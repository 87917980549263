<template>
  <v-container fluid>
    <basic-card
      title="$l.paths.apiInvestigation"
      class="mt-9"
    >
      <app-api-call
        class="ma-0 pa-0 mb-3"
        :call-result="callResult"
      />

      <CheckList v-if="callResult.finished && !callResult.error && callResult.authorized" />
    </basic-card>
  </v-container>
</template>

<script>
import CheckList from './CheckList.vue'

export default {
  name: 'ApiInvestigationWrapper',
  components: {
    CheckList
  },
  data () {
    return {
      callResult: { finished: false, authorized: false, error: null, info: null }
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.callResult.finished = false
      this.callResult.error = null
      this.callResult.info = this.$i18n.t('$l.info.checkingRights')
      this.$xapi.post('endpointsUserProfile/hasRequiredRights', ['RIGHT_APP_ENABLE_PER_CLIENT_INVESTIGATION_API'])
        .then(r => {
          this.callResult.authorized = true
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.callResult.finished = true
          this.callResult.info = null
        })
    }
  }
}
</script>
