<template>
  <ApiInvestigationWrapper />
</template>

<script>
import ApiInvestigationWrapper from '@/components/api/ApiInvestigationWrapper'

export default {
  name: 'ApiInvestigation',
  components: {
    ApiInvestigationWrapper
  }
}
</script>
